<template>
    <div class="template_info">
         <div class="head">
             <div >
                 <div class="flex_inline" @mouseover="show('Title')" @mouseleave="hide" style="line-height: 35px">
                     <i class="iconfont icon-lujing" style="color: #f15a4a;font-size: 30px;margin-right: 23px"></i>
                     <div v-if="FieldText == 'Title'" class="flex" style="width: 150px">
                         <a-input class="group-item" v-model="Title"   />
                         <span @click="submitField" class="sure-gou ant-menu-item-selected"><a-icon type="check" /></span>
                     </div>
                     <h1 v-else> {{infoData.Title}} <i v-if="StatusText =='Title' && infoData.power==1" @click="SaveField($event,'Title')" class="iconfont icon-bianji ant-menu-item-selected"></i></h1>
                     
                 </div>

             </div>

             <div class="flex_inline" style="width: 100%">
                 <div @mouseover="show('Category')" @mouseleave="hide" class="group">
                     <label class="label-text">活动类型 <i v-if="StatusText =='Category' && infoData.power==1" @click="SaveField($event,'Category')" class="iconfont icon-bianji ant-menu-item-selected"></i></label>
                     <div   v-if="FieldText == 'Category'" class="flex item-hide" style="width: 150px">
                         <a-select class="group-item" v-model="Category"  placeholder="请选择">
                             <a-select-option v-if="categoryList" v-for="item in categoryList" :value="item.Id">{{item.title}}</a-select-option>
                         </a-select>
                         <span @click="submitField" class="sure-gou ant-menu-item-selected"><a-icon type="check" /></span>
                     </div>
                     <span v-else class="item-text" >{{infoData.Name}}</span>

                 </div>
                 <div @mouseover="show('PersonNum')" @mouseleave="hide" class="group">
                     <label class="label-text">所需员工 <i v-if="StatusText =='PersonNum' && infoData.power==1" @click="SaveField($event,'PersonNum')" class="iconfont icon-bianji ant-menu-item-selected"></i></label>

                     <div v-if="FieldText == 'PersonNum'" class="flex" style="width: 150px">
                         <a-input class="group-item" v-model="PersonNum"  :defaultValue="infoData.PersonNum" />
                         <span @click="submitField" class="sure-gou ant-menu-item-selected"><a-icon type="check" /></span>
                     </div>
                     <span v-else class="item-text" >{{infoData.PersonNum}}</span>

                 </div>
                 <div @mouseover="show('Score')" @mouseleave="hide" class="group">
                     <label class="label-text">难易程度 <i v-if="StatusText =='Score' && infoData.power==1" @click="SaveField($event,'Score')" class="iconfont icon-bianji ant-menu-item-selected"></i></label>
                     <div v-if="FieldText == 'Score'"   class="flex" style="width: 150px" >
                         <a-rate  v-model="Score"  class="group-item" style="color: #2994FFFF;font-size: 16px;" />
                         <span @click="submitField" class="sure-gou ant-menu-item-selected"><a-icon type="check" /></span>
                     </div>
                     <div  v-else class="item-text" >
                         <a-rate :defaultValue="Score" v-if="Score"  style="color: #2994FFFF;font-size: 16px;" disabled />
                     </div>

                 </div>
                 <div @mouseover="show('Tags')" @mouseleave="hide" class="group" style="width: auto">
                     <label class="label-text">活动标签 <i v-if="StatusText =='Tags' && infoData.power==1" @click="SaveField($event,'Tags')" class= "iconfont icon-bianji ant-menu-item-selected"></i></label>
                     <div class="flex" v-if="FieldText == 'Tags'">

                         <a-tag v-if="Tags.length>0" v-for="item in Tags" closable  :afterClose="() => handleClose(item)">{{item}}</a-tag>
                         <a-input
                                 v-if="inputVisible"
                                 ref="input"
                                 type="text"
                                 size="small"
                                 :style="{ width: '78px' }"
                                 :value="inputValue"
                                 @change="handleInputChange"
                                 @blur="handleInputConfirm"
                                 @keyup.enter="handleInputConfirm"
                         />
                         <a-tag v-else @click="showInput" style="background: #fff; borderStyle: dashed;">
                             <a-icon type="plus" /> 自定义
                         </a-tag>

                         <span @click="submitField" class="sure-gou ant-menu-item-selected"><a-icon type="check" /></span>
                     </div>
                     <div v-else>
                         <a-tag v-if="infoData.Tags.length>0" v-for="item in infoData.Tags">{{item}}</a-tag>
                     </div>


                 </div>
             </div>

         </div>


        <div class="content">
            <div>
                <a-button-group size="large">
                    <a-button class="MyStatus" :class="MyStatus?'':'ant-btn-primary'" @click="_tab(1)">方案策划</a-button>
                    <a-button class="MyStatus" :class="MyStatus?'ant-btn-primary':''" @click="_tab(2)">活动流程</a-button>
                </a-button-group>
            </div>

            <div class="flex_inline" style="width: 100%">

                <div class="content-warp" style="width: 950px;cursor: pointer">
                    <!--方案策划-->
                    <div v-if="!MyStatus">
                        <div>
                            <div class="flex_inline" @click="_tabChange(1)">
                                <h1 class="content-title">活动介绍</h1>
                                <a-icon class="triangle" :type="TabKeys==1?'caret-right':'caret-down'" />
                                <!--<div :class="TabKeys==1?'triangle':'triangle-down'"></div>-->
                            </div>
                            <div v-if="TabKeys !=1">
                                <div @mouseover="show('Title')" @mouseleave="hide"  class="flex_inline" style="width: 100%;margin-bottom: 10px" :class="StatusText=='Title'?'active-bg':''">
                                    <label class="label-text" style="width:60px">活动主题</label>
                                    <div v-if="FieldText == 'Title'" class="flex" style="width: 390px">
                                        <a-input class="group-item_two" v-model="Title"  :defaultValue="infoData.Title" />
                                        <span @click="submitField" class="sure-gou ant-menu-item-selected"><a-icon type="check" /></span>
                                    </div>
                                    <div v-else class="flex" style="width: 800px">
                                        <span  class="item-text" >{{infoData.Title}}</span>
                                        <div v-if="StatusText=='Title'">
                                            <i @click="SaveField($event,'Title')"  class="iconfont icon-bianji" style="margin-right: 10px"></i>
                                            <i class="iconfont icon-shanchu-"></i>
                                        </div>
                                    </div>

                                </div>
                                <div @mouseover="show('Category')" @mouseleave="hide"  class="flex_inline" style="width: 100%;margin-bottom: 10px" :class="StatusText=='Category'?'active-bg':''">
                                    <label class="label-text" style="width:60px">活动分类</label>
                                    <div v-if="FieldText == 'Category'" class="flex" style="width: 390px">
                                        <a-select class="group-item_two" v-model="Category"  placeholder="请选择">
                                            <a-select-option v-if="categoryList" v-for="item in categoryList" :value="item.Id">{{item.title}}</a-select-option>
                                        </a-select>
                                        <span @click="submitField" class="sure-gou ant-menu-item-selected"><a-icon type="check" /></span>
                                    </div>
                                    <div v-else class="flex" style="width: 800px">
                                        <span  class="item-text" >{{infoData.Name}}</span>
                                        <div v-if="StatusText=='Category'">
                                            <i @click="SaveField($event,'Category')"  class="iconfont icon-bianji" style="margin-right: 10px"></i>
                                            <i class="iconfont icon-shanchu-"></i>
                                        </div>
                                    </div>

                                </div>
                                <div @mouseover="show('Free')" @mouseleave="hide"  class="flex_inline" style="width: 100%;margin-bottom: 10px" :class="StatusText=='Free'?'active-bg':''">
                                    <label class="label-text" style="width:60px">费用项</label>
                                    <div v-if="FieldText == 'Free'" class="flex" style="width: 390px">
                                        <a-input class="group-item_two" v-model="Free"  :defaultValue="infoData.Free" />
                                        <span @click="submitField" class="sure-gou ant-menu-item-selected"><a-icon type="check" /></span>
                                    </div>
                                    <div v-else class="flex" style="width: 800px">
                                        <span  class="item-text" >{{infoData.Free}}</span>
                                        <div v-if="StatusText=='Free'">
                                            <i @click="SaveField($event,'Free')"  class="iconfont icon-bianji" style="margin-right: 10px"></i>
                                            <i class="iconfont icon-shanchu-"></i>
                                        </div>
                                    </div>

                                </div>
                                <div @mouseover="show('Equipment')" @mouseleave="hide"  class="flex_inline" style="width: 100%;margin-bottom: 10px" :class="StatusText=='Equipment'?'active-bg':''">
                                    <label class="label-text" style="width:60px">活动设备</label>
                                    <div v-if="FieldText == 'Equipment'" class="flex" style="width: 390px">
                                        <a-input class="group-item_two" v-model="Equipment"  :defaultValue="infoData.Equipment" />
                                        <span @click="submitField" class="sure-gou ant-menu-item-selected"><a-icon type="check" /></span>
                                    </div>
                                    <div v-else class="flex" style="width: 800px">
                                        <span  class="item-text" >{{infoData.Equipment}}</span>
                                        <div v-if="StatusText=='Equipment'">
                                            <i @click="SaveField($event,'Equipment')"  class="iconfont icon-bianji" style="margin-right: 10px"></i>
                                            <i class="iconfont icon-shanchu-"></i>
                                        </div>
                                    </div>


                                </div>
                                <div @mouseover="show('PersonNum')" @mouseleave="hide"  class="flex_inline" style="width: 100%;margin-bottom: 10px" :class="StatusText=='PersonNum'?'active-bg':''">
                                    <label class="label-text" style="width:60px">所需员工</label>
                                    <div v-if="FieldText == 'PersonNum'" class="flex" style="width: 390px">
                                        <a-input class="group-item_two" v-model="PersonNum"  :defaultValue="infoData.PersonNum" />
                                        <span @click="submitField" class="sure-gou ant-menu-item-selected"><a-icon type="check" /></span>
                                    </div>
                                    <div v-else class="flex" style="width: 800px">
                                        <span  class="item-text" >{{infoData.PersonNum}}</span>
                                        <div v-if="StatusText=='PersonNum'">
                                            <i @click="SaveField($event,'PersonNum')"  class="iconfont icon-bianji" style="margin-right: 10px"></i>
                                            <i class="iconfont icon-shanchu-"></i>
                                        </div>
                                    </div>


                                </div>
                                <div @mouseover="show('Content')" @mouseleave="hide"  class="flex_inline" style="width: 100%;margin-bottom: 10px" :class="StatusText=='Content'?'active-bg':''">
                                    <label class="label-text" style="width:60px">活动说明</label>
                                    <div v-if="FieldText == 'Content'" class="flex" style="width: 700px">
                                        <!--<a-input  v-model="Content"  :defaultValue="infoData.Content" />-->
                                        <div class="group-item_two"  style="width: 700px">
                                            <quill-editor

                                                    v-model="Content"
                                                    ref="myQuillEditor"
                                                    :options="editorOption"
                                                    @change="onEditorChange($event)">
                                            </quill-editor>
                                        </div>
                                        <span @click="submitField" class="sure-gou ant-menu-item-selected"><a-icon type="check" /></span>
                                    </div>
                                    <div v-else class="flex" style="width: 800px">
                                        <div v-html="infoData.Content"></div>
                                        <!--<span  class="item-text" >{{infoData.Content}}</span>-->
                                        <div v-if="StatusText=='Content'">
                                            <i @click="SaveField($event,'Content')"  class="iconfont icon-bianji" style="margin-right: 10px"></i>
                                            <i class="iconfont icon-shanchu-"></i>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="flex_inline" @click="_tabChange(2)" style="cursor: pointer">
                                <h1 class="content-title">附件</h1>
                                <a-icon class="triangle" :type="TabKeys==2?'caret-right':'caret-down'" />
                                <!--<div :class="TabKeys ==2?'triangle':'triangle-down'"></div>-->
                            </div>
                            <div class="flex-inline" v-if="TabKeys !=2" >
                                <div class="enclosure" v-if="Enclosure.length>0" v-for="(item,index) in Enclosure">
                                    <div class="enclosure-file">
                                        <img v-if="item.FileStatus ==1" :src="item.FileUrl+'?imageView2/1/w/246/h/168'" alt="">
                                        <i v-else-if="item.FileStatus ==2" class="iconfont icon-tubiao_shipin"></i>
                                    </div>
                                    <div class="flex file-warp">
                                        <span class="file-name" >{{item.FileName}}</span>
                                        <a-dropdown>
                                            <a class="ant-dropdown-link" href="#" > <i @mouseover="_show(1,1)" @mouseout="_hide(1)" class="iconfont icon-shenglve" :style="active1==1?'':'color:#999999FF'"></i> </a>
                                            <a-menu slot="overlay">
                                                <a-menu-item>
                                                    <a href="javascript:;" @click="SaveEnclosure(index)"><i class="iconfont icon-bianji"></i>&nbsp;编辑</a>
                                                </a-menu-item>
                                                <a-menu-item>
                                                    <a href="javascript:;" @click="downEnclosure(index)"><i class="iconfont icon-bianji"></i>&nbsp;下载</a>
                                                </a-menu-item>
                                                <a-menu-item>
                                                    <a href="javascript:;" ><i   class="iconfont icon-bianji"></i>&nbsp;预览</a>
                                                </a-menu-item>
                                                <a-menu-item>

                                                    <a-popconfirm  placement="bottom" cancelText="取消" okText="确定" @confirm="DelEnclosure(index)" @cancel="maxClassify=-1">
                                                        <template slot="title">
                                                            <p>确定删除么？</p>
                                                        </template>
                                                        <i class="iconfont icon-shanchu- ">&nbsp;删除</i>
                                                    </a-popconfirm>
                                                </a-menu-item>
                                            </a-menu>
                                        </a-dropdown>
                                        <a-drawer
                                                title="附件修改"
                                                width="600"
                                                :closable="false"
                                                @close="onChildrenDrawerClose"
                                                :visible="childrenDrawer"
                                        >
                                            <a-input v-if="filesName" v-model="filesName"  class="drawer-item" placeholder="请输入" />
                                            <div class="drawer-buttom">
                                                <a-button style="marginRight: 8px" @click="onClose">
                                                    取消
                                                </a-button>
                                                <a-button @click="fileSave" type="primary">
                                                    修改
                                                </a-button>
                                            </div>
                                        </a-drawer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--活动流程-->
                    <div v-else>
                        <div class="flex_inline">
                            <h1 class="content-title">活动流程</h1>
                            <a-icon class="Add-bth" @click="ActivityFlow" type="plus-circle" />
                        </div>
                        <div>
                            <div v-if="ParentList" v-for="(item,index) in ParentList">
                                <!--第一层-->
                                <div class="flex first-list first-list-bg" @click="show_second_flow(item.key)"  @mouseover="show_flow(item.key)" @mouseleave="hide_flow">
                                    <div ><a-icon type="caret-down"  /> <span class="first-list-title">{{item.Name}}</span></div>
                                    <div v-if="FlowKey == item.key" >
                                        <i class="iconfont icon-bianji" @click="flowSave(index)"></i>
                                        <a-popconfirm  placement="bottom" cancelText="取消" okText="确定" @confirm="DelFlow(item.Id)" @cancel="maxClassify=-1">
                                            <template slot="title">
                                                <p>确定删除么？</p>
                                            </template>
                                            <i class="iconfont icon-shanchu- first-lis-font"></i>
                                        </a-popconfirm>
                                        <a-icon type="plus-circle" @click="addFlow(item.Id)" />
                                    </div>
                                </div>
                                <!--第二层-->
                                <div class="flex first-list" v-if="item.Children.length>0 && SecondKey != item.key" v-for="(items,k) in item.Children" @mouseover="show_flow(items.key)" @mouseleave="hide_flow">
                                    <span class="second-list-title">{{items.Name}}</span>
                                    <div v-if="items.key ==FlowKey ">
                                        <i class="iconfont icon-bianji" @click="flowSave(index,k)"></i>
                                        <a-popconfirm  placement="bottom" cancelText="取消" okText="确定" @confirm="DelFlow(items.Id)" @cancel="maxClassify=-1">
                                            <template slot="title">
                                                <p>确定删除么？</p>
                                            </template>
                                            <i class="iconfont icon-shanchu- first-lis-font" style="margin-right: 0"></i>
                                        </a-popconfirm>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="content-warp" style="flex: 1" >
                    <a-input placeholder="添加修改意见" style="margin-bottom: 15px">
                        <i class="iconfont icon-bianji" slot="prefix" @click="changeOpinion"></i>
                    </a-input>
                    <a-steps direction="vertical"   >
                        <a-step size="small">
                            <template slot="title">
                                <a-button type="primary">2019.11.20</a-button>
                            </template>

                        </a-step>
                        <a-step >
                            <template slot="title">
                                 <div class="flex_inline">
                                     <a-avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                                     <div class="comment">
                                         <span class="comment-name">朱敏（顾问）</span>
                                         <span class="comment-time">2019.11.20 12:30</span>
                                         <span class="comment-detail">建议把场地布置如下，宝宝更喜欢彩色</span>
                                         <div class="comment-img" v-viewer>
                                             <img src="http://sk-ims-cabinet.sikegroup.com/Fm3bJBZWCFPMj-71vEia23TsD1rw?imageView2/1/w/100/h/100" alt="" url="http://sk-ims-cabinet.sikegroup.com/Fm3bJBZWCFPMj-71vEia23TsD1rw">
                                             <img src="http://sk-ims-cabinet.sikegroup.com/Fm3bJBZWCFPMj-71vEia23TsD1rw?imageView2/1/w/100/h/100" alt="" url="http://sk-ims-cabinet.sikegroup.com/Fm3bJBZWCFPMj-71vEia23TsD1rw">
                                         </div>
                                         <div>
                                             <i class="iconfont icon-wenjian3" style="color: #666666FF;font-size: 16px"></i>
                                             <span class="enclosure-name">附件名称</span>
                                             <span class="enclosure-down">下载</span>
                                         </div>
                                         <div class="flex">
                                             <div>&nbsp;</div>
                                             <div>
                                                 <i class="iconfont icon-pinglun" style="margin-right: 20px">&nbsp;&nbsp;评论</i>
                                                 <i class="iconfont icon-fuwuzhongjiewuye">&nbsp;&nbsp;赞</i>
                                             </div>
                                         </div>
                                         <span class="comment-name">朱敏（顾问）</span>
                                         <span class="comment-time">2019.11.20 12:30</span>
                                         
                                     </div>
                                 </div>
                            </template>
                        </a-step>
                        <a-step title="Waiting" description="This is a description." />
                    </a-steps>
                </div>
            </div>
        </div>

        <a-drawer
                title="活动流程"
                width="600"
                :closable="false"
                @close="onDrawerClose"
                :visible="visible"
        >
            <a-input v-model="FlowName"  class="drawer-flow" placeholder="请输入" />
            <a-select class="drawer-flow" v-model="ParentId"  placeholder="请选择">
                <a-select-option value="0">无</a-select-option>
                <a-select-option v-if="ParentData" v-for="item in ParentData" :value="item.Id">{{item.Name}}</a-select-option>
            </a-select>
            <div class="drawer-buttom">
                <a-button style="marginRight: 8px" @click="onDrawerClose">
                    取消
                </a-button>
                <a-button @click="flowSubmit" type="primary">
                    确认
                </a-button>
            </div>
        </a-drawer>

    </div>
</template>

<script>
    import Vue from 'vue'
    import VueCropper from 'vue-cropper'
    Vue.use(VueCropper)
    import VueQuillEditor from 'vue-quill-editor'
    import 'viewerjs/dist/viewer.css'
    import Viewer from 'v-viewer'
    Vue.use(Viewer)
    // require styles
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    Vue.use(VueQuillEditor, /* { default global options } */)
    export default {
        name: "template_info",
        data(){
            return{
                userinfo:JSON.parse(sessionStorage.getItem("userInfo")),
                Id:this.$route.query.id,
                infoData:'',
                categoryList:'',
                FieldText:'',
                Category:'',
                StatusText:'',
                Score:'',
                Title:'',
                PersonNum:'',
                Objective:'',
                Tags:'',
                Free:'',
                Equipment:'',
                Content:'',
                CoverImg:'',
                Enclosure:'',
                inputVisible:'',
                inputValue:'',
                editorOption: { /* quill options */ } ,
                MyStatus:false,
                active1:-1,
                active2:-1,
                active3:-1,
                active4:-1,
                childrenDrawer:false,
                filesName:'',
                TabKeys:'',
                visible:false,
                FlowName:'',
                ParentId:'',
                FlowId:'',
                ParentList:'',
                ParentData:'',
                FlowKey:'',
                SecondKey:'',

            }
        },
        created(){
            if(this.Id){
                this.loadData();
            }
            this._categoryList();
            
        },
        methods:{
            //活动流程
            ActivityFlow(){
                this.visible = true;

            },
            flowSubmit(){
                let self = this;
                this.$axios.post(1066,{
                    uid:self.userinfo.uid,
                    UserName:self.userinfo.name,
                    fk_id:self.Id,
                    ParentId:self.ParentId,
                    Id:self.FlowId,
                    Name:self.FlowName,
                },res=>{
                    if(res.data.code==1){
                        this.$message.success(res.data.message);
                        self.loadData();
                        self.FlowName = '';
                        self.FlowId =  '';
                        self.ParentId = '';
                        self.visible = false;
                    }else{
                        this.$message.error(res.data.message);
                    }
                })
            },
            DelFlow(id){
                let self = this;
                this.$axios.get(1067,{
                    uid:self.userinfo.uid,
                    Id:id,
                },res=>{
                    if(res.data.code ==1){
                        this.$message.success(res.data.message);
                         self.loadData();
                    }else{
                        this.$message.error(res.data.message);
                    }
                })
            },
            flowSave(index,i){
                if(typeof(i) !='undefined'){
                    this.FlowName = this.ParentList[index].Children[i].Name;
                    this.FlowId =  this.ParentList[index].Children[i].Id;
                    this.ParentId = this.ParentList[index].Children[i].ParentId;
                }else{
                    this.FlowName = this.ParentList[index].Name;
                    this.FlowId =  this.ParentList[index].Id;
                    this.ParentId = this.ParentList[index].ParentId
                }
                this.visible = true;
            },
            addFlow(id){
                this.ParentId = id;
                this.visible = true;
            },
            show_flow(key){
                this.FlowKey = key;
            },
            hide_flow(){
                this.FlowKey = -1;
            },
            show_second_flow(key){
                if(this.SecondKey == key){
                    this.SecondKey = '';
                }else{
                    this.SecondKey = key;
                }
            },
            loadData(){
                let self = this;
                this.$axios.get(1064,{
                    Id:self.Id,
                    uid:self.userinfo.uid,
                },res=>{
                    if(res.data.code==1){
                        self.infoData = res.data.message;
                        self.Score = res.data.message.Score;
                        self.Title = res.data.message.Title;
                        self.Category = res.data.message.Category;
                        self.PersonNum = res.data.message.PersonNum;
                        self.Objective = res.data.message.Objective;
                        self.Tags = res.data.message.Tags;
                        self.Free = res.data.message.Free;
                        self.Equipment = res.data.message.Equipment;
                        self.Content = res.data.message.Content;
                        self.CoverImg = res.data.message.CoverImg;
                        self.Enclosure = res.data.message.Enclosure;
                        self.ParentList = res.data.message.ParentList;
                        self.ParentData = res.data.message.ParentData;
                    }else{
                        this.$message.error(res.data.message);
                    }
                })
            },
            changeOpinion(){
                console.log(22);
            },
            SaveField(e,field){
                this.FieldText = field;
            },
            submitField(){
                let self = this;
                this.$axios.post(1060,{
                    Title:self.Title,
                    PersonNum:self.PersonNum,
                    Category:self.Category,
                    Score:self.Score,
                    Tags:JSON.stringify(self.Tags),
                    Free:self.Free,
                    Equipment:self.Equipment,
                    Content:self.Content,
                    CoverImg:self.CoverImg,
                    Objective:self.Objective,
                    Enclosure:JSON.stringify(self.Enclosure),
                    Id:self.Id,
                    uid:self.userinfo.uid,
                },res=>{
                    if(res.data.code ==1){
                        //text_obj.style.display = 'block';
                        // save_obj.style.display = 'none';
                        self.StatusText = '';
                        self.FieldText = '';
                        this.$message.success(res.data.message);
                        self.loadData();
                    }else{
                        this.$message.error(res.data.message);
                    }
                })
            },
            show(value){
                this.StatusText = value;
            },
            hide(){
                if(this.StatusText !='Category'){
                    // this.FieldText = '';
                }
                console.log(this.FieldText);
                this.StatusText = '';
            },
            handleClose(removedTag){
                this.Tags = this.Tags.filter(tag => tag !== removedTag);
            },
            showInput() {
                this.inputVisible = true;
                this.$nextTick(function() {
                    this.$refs.input.focus();
                });
            },

            handleInputChange(e) {
                this.inputValue = e.target.value;
            },

            handleInputConfirm() {
                const inputValue = this.inputValue;
                let tags = this.Tags;
                if (inputValue && tags.indexOf(inputValue) === -1) {
                    tags = [...tags, inputValue];
                }
                Object.assign(this, {
                    tags,
                    inputVisible: false,
                    inputValue: '',
                });
                this.Tags = tags;
            },
            _tab(i){
                if(i==1){
                    this.MyStatus = false;
                }else{
                    this.MyStatus = true;
                }

            },
            _tabChange(i){
                if(this.TabKeys){
                    if(this.TabKeys == i){
                        this.TabKeys = '';
                    }else{
                        this.TabKeys = i;
                    }
                }else{
                    this.TabKeys = i;
                }

                console.log(this.TabKeys);
            },
            //富文本
            onEditorChange(){}, // 内容改变事件
            _show(index,i){
                if(i ==1){
                    this.active1 = index;
                }else if(i==2){
                    this.active2 = index;
                }else if(i==3){
                    this.active3 = index;
                }else if(i==2){
                    this.active4 = index;
                }

            },
            _hide(i){
                if(i==1){
                    this.active1 = -1;
                }else if(i==2){
                    this.active2 = -1;
                }else if(i==3){
                    this.active3 = -1;
                }else if(i==2){
                    this.active4 = -1;
                }
            },
            SaveEnclosure(index){
                this.fileIndex = index;
                this.filesName = this.Enclosure[index].FileName;
                this.childrenDrawer = true;
            },
            fileSave(){
                this.Enclosure[this.fileIndex].FileName = this.filesName;
                this.fileIndex = '';
                this.filesName = '';
                this.submitField();
                this.childrenDrawer = false;
            },
            DelEnclosure(index){
                this.Enclosure.splice(index,1);
                this.submitField();
            },
            downEnclosure(index){
                window.open(this.Enclosure[index].FileUrl+'?attname='+this.Enclosure[index].FileName);
            },
            onChildrenDrawerClose() {
                this.childrenDrawer = false;
            },
            onClose(){
                this.visible= false;
            },
            _categoryList(){
                let self = this;
                this.$axios.get(1200,{
                    label:'activitytemplate',
                },res=>{
                    self.categoryList = res.data.data;
                })
            },
            onDrawerClose(){
                this.FlowName = '';
                this.FlowId =  '';
                this.ParentId = '';
                this.visible = false;
            },

        }
    }
</script>

<style lang="less" scoped>
    *{
        font-family:Microsoft YaHei;
    }
    .template_info{
        background:rgba(247,248,250,1);
        min-height: 800px;
    }
    .flex_inline{
        display: inline-flex;
        position: relative;
    }
    .group{
        width: auto;
        min-width: 200px;
    }
    .label-text{
        color: #999999FF;
        font-size: 12px;
        height: 30px;
        line-height: 30px;
        display: block;
        .icon-bianji{
            margin-left: 10px;
        }
    }
    .active-bg{
        background:rgba(243,243,249,1);
    }
    .item-text{
        color: #242424FF;
        font-size: 12px;
        display: block;
    }
    .group-item{
        width: 120px;
    }
    .group-item_two{
        width: 350px;
    }
    .sure-gou{
        margin-left: 15px;
        font-size: 16px;
        cursor: pointer;
    }
    .head{
        padding: 30px;
        background: #ffffff;
        h1{
            position: relative;
            font-weight:400;
            font-size:20px;
            color:rgba(102,102,102,1);
        }

    }
    .content{
        background: #ffffff;
        min-height: 500px;
        margin-top: 15px;
        padding: 8px 20px;
    }
    .MyStatus{
        font-size: 14px;
    }
    .content-warp{
        border:1px solid rgba(204,209,220,1);
        border-radius:2px;
        margin-right: 15px;
        min-height: 200px;
        padding: 20px 15px;
        .content-title{
            font-size:13px;
            font-weight:bold;
            color:rgba(102,102,102,1);
            border-left: 4px solid #1890ff;
            padding-left: 15px;
        }
        .triangle{
            margin-left:5px;
            margin-top: 4px;
            color: red;
        }
        
    }
    .enclosure{
        width: 145px;
        margin-right: 15px;
        border:1px solid rgba(224,225,226,1);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 10px;
        display: inline-block;
        .enclosure-file{
            height: 96px;
            width: 100%;
            line-height: 95px;
            text-align: center;
            background: #ffffff;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .file-warp{
            padding: 5px 10px;

        }
        .file-name{

            width: 68px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color:rgba(45,62,93,1);
            font-size: 12px;
        }
    }
    .drawer-buttom{
        position: absolute;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        left: 0;
        background: #ffffff;
        borderRadius: 0 0 4px 4px;
    }
    .drawer-item{
        height: 36px;
        line-height: 36px;
        width: 390px;
    }
    .Add-bth{
        color: rgba(41,148,255,1);
        font-size: 20px;
        margin-left: 15px;
    }
    .first-list-bg{
        background:rgba(243,243,249,1);
    }
    .first-lis-font{
        margin-left: 15px;
        margin-right: 15px;
    }
    .first-list{
        height:43px;
        opacity:1;
        border-radius:4px 4px 0px 0px;
        color: rgba(153,153,153,1);
        padding: 0px 20px;
        border:1px solid rgba(224,225,226,1);
        .first-list-title{
            font-size:14px;
            font-weight:bold;
            color:rgba(36,36,36,1);
            margin-left: 8px;
        }
    }
    .second-list-title{
        font-size:14px;
        font-weight:400;
        color:rgba(45,62,93,1);
        margin-left: 22px;
    }
    .drawer-flow{
        display: block;
        width: 100%;
        margin-bottom: 20px;
    }
    .comment{
        font-size: 12px;
        span{
            display: block;
        }
        .comment-name{
            color:rgba(102,102,102,1);
        }
        .comment-time{
            color:rgba(153,153,153,1);
        }
        .comment-detail{
            color:rgba(102,102,102,1);
        }
        .comment-img{
            img{
                width: 97px;
                height: 100px;
                display: inline-block;
                margin-right: 10px;
            }
        }
        .enclosure-name{
            color:#666666FF;
            display: inline-block;
            margin-left: 5px;
            margin-right: 5px;
        }
        .enclosure-down{
            display: inline-block;
            color: #2994FFFF;
        }
    }
</style>